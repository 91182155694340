.input {
  height: 42px;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  border: 0;
  outline: 0;
  padding: 0px 0px 0px 12px;
  color: #113357;
}
