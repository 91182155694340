.buttonGroup {
  display: flex;
}

.buttonGroup > *:first-child {
  border-radius: 2px 0 0 2px;
}

.buttonGroup > *:last-child {
  border-radius: 0 2px 2px 0;
}

.buttonGroup > *:only-child {
  border-radius: 2px;
}

.button {
  display: flex;
  flex-flow: row;
  place-content: center;
  place-items: center;
  background-color: inherit;
  border: none;
  outline: none;
  text-decoration: none;
  text-transform: none;
  margin: auto;
  font-family: inherit;
  font-size: 1em;
  line-height: 1em;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border-radius: 2px;
  color: white;
  height: 100%;
  padding: 3px 6px;
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox {
  composes: button;
  background-color: #354660;
  aspect-ratio: 1;
}

.checkbox:checked {
  background-color: #13bcb4;
}

.button:hover {
  background-color: #d9d9d933;
}

.dropdownButton {
  composes: button;
}

.dropdownButton::after {
  content: "";
  border: solid #657e9a;
  border-width: 0 2px 2px 0;
  margin-left: 8px;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
