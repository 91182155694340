.container {
  display: flex;
  width: 162px;
  flex-direction: column;
}

.hsvPicker {
  height: 148px;
  position: relative;
  overflow: hidden;
}

.huePicker {
  width: 162px;
  height: 11px;
  position: relative;
  background: -moz-linear-gradient(
    right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
  background: -ms-linear-gradient(
    right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
  background: -o-linear-gradient(
    right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
  background: -webkit-gradient(
    linear,
    left,
    right,
    from(#ff0000),
    color-stop(0.17, #ffff00),
    color-stop(0.33, #00ff00),
    color-stop(0.5, #00ffff),
    color-stop(0.67, #0000ff),
    color-stop(0.83, #ff00ff),
    to(#ff0000)
  );
  background: -webkit-linear-gradient(
    right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
  background: linear-gradient(
    to right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
}

/* black to transparent square */
.black {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
}

/* white to transparent square */
.white {
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, #fff, rgba(0, 0, 0, 0));
}

.pointer {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
}

.hexContainer {
  display: flex;
  height: 46px;
  padding: 8px;
  background-color: #113357;
}

.hexContainer input {
  outline: none;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #dadce0;
  background-color: #113357;
  border: 1px solid #657e9a;
}
