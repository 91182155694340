.fieldContainer {
  display: flex;
  flex-direction: column;
}

.fieldContainer .message {
  height: 12px;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 0 20px;
}

.fieldContainer .error {
  color: #f05c30;
}

.link {
  color: #1973ff;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.fieldContainer .hint {
  color: #8b8b8b;
}

.fieldLabel {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.fieldInputContainer {
  width: unset;
  border: 1px solid #dadce0;
  transition: border-color 200ms ease;
  position: relative;
  display: flex;
  overflow: hidden;
}

.fieldInputContainer.isFocused {
  outline: 1px solid #1973ff;
}

.fieldInputContainer.isError {
  border: 1px solid #f05c30;
}
