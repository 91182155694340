.logo {
  font-family: "Sora";
  font-style: normal;
  color: #113357;
  font-size: 21px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.clickable:hover {
  cursor: pointer;
}

.logo small {
  font-weight: 400;
  font-size: 12px;
}
