.popover {
  z-index: 1;
}

.tooltip {
  width: max-content;
  padding: 5px 10px;
  border: none !important;

  background-color: var(--dark);
  color: #fff;
  text-align: center;
  z-index: 2147483645;

  pointer-events: none;
  user-select: none;

  opacity: 0;
  transition: opacity 200ms;
}

.arrow {
  background-color: white;
  position: absolute;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  padding: unset !important;
  z-index: -1;
}
