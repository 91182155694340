.wrapper {
  background: var(--dark);
  border-radius: 4px;
  padding: 8px;
  z-index: 100;
}

.toolbarActions {
  width: fit-content;
  display: flex;
  gap: 10px;
  max-height: 42px;
  align-items: center;
}

.remove {
  all: unset;
  cursor: pointer;
  display: flex;
  padding: 0px 4px;
}

.remove:hover {
  background-color: #354660;
}
