.container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.200691);
  background-color: white;
  /* gap: 15px; */
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 300px;
  max-height: 600px;
}

.columnsList {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.columnItem {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.columnItem:hover {
  background-color: #f1f3f4;
  cursor: pointer;
}

.separator {
  height: 1px;
  background-color: #dadce0;
  margin: 10px 15px;
}

.error {
  color: red;
  font-size: 14px;
  margin: 10px;
}
