.container button:not(.menuButton):not(.inRange, .rangeStart) {
  border: 0;
  padding: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
  background: none;
}

.container button img {
  filter: invert(95%) sepia(61%) saturate(6313%) hue-rotate(201deg) brightness(94%) contrast(91%);
}

.header {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header button {
  outline: none;
  background-color: transparent;
  border: none;
}

.hr {
  margin-bottom: 20px;
  margin-top: 10px;
  border-top: 0.5px solid #f0f0f0;
}

.calendar {
  display: grid;
  grid: auto / repeat(7, 37px);
  justify-items: center;
  row-gap: 7px;
  box-sizing: border-box;
}

.dayName {
  font-family: "Poppins";
  display: inline;
  font-weight: 500;
  font-size: 12px;
  height: 24px;
  width: 37px;
  line-height: 18px;
  text-align: center;
  color: white;
}

.day {
  border: none;
  background-color: transparent;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 11px;
  line-height: 16.5px;
  text-align: center;
  letter-spacing: -0.4px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  width: 37px;
  height: 37px;
}

.selected {
  background-color: var(--blue) !important;
  color: white !important;
}

.inRange {
  position: relative;
  background-color: rgba(25, 115, 255, 0.3);
}

.rangeStart {
  position: relative;
  border-radius: 50%;
  z-index: 1;
}
/* 
.calendar:not(:has(.rangeEnd)) .rangeStart:before {
  background-color: transparent;
} */

.rangeStart:before {
  content: "";
  background-color: rgba(25, 115, 255, 0.3);
  position: absolute;
  height: 100%;
  width: 50%;
  left: 50%;
  z-index: -1;
}

.rangeEnd {
  position: relative;
  border-radius: 50%;
  z-index: 1;
}

.rangeEnd:before {
  content: "";
  background-color: rgba(25, 115, 255, 0.3);
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}

.startAndEndDate:before {
  content: none;
}

.day:not(.selected):not(.inRange):hover {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.day.inRange:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2) !important;
  z-index: 1;
}

.day.inRange:hover:before {
  content: "";
  background-color: rgba(25, 115, 255, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  z-index: -1;
}
.day.selected.rangeStart:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.day.selected.rangeStart:hover:before {
  content: "";
  background-color: rgba(25, 115, 255, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  z-index: -1;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.day.inRange.rangeEnd:hover:before {
  content: "";
  background-color: rgba(25, 115, 255, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  z-index: -1;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.isOutOfCurrentMonth {
  color: #c8c8c8;
}

.isSunday.inRange {
  width: 37px;
  left: 0px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.isSunday.inRange:hover:before {
  width: 37px;
  left: 0px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.isSaturday.inRange {
  width: 37px;
  left: 0px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.isSaturday.inRange:hover:before {
  width: 37px;
  left: 0px;
  background-color: rgba(25, 115, 255, 0.3);
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.calendarActions {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  margin-top: 10px;
}

.calendarAction:hover {
  text-decoration: underline;
}

.calendarAction {
  all: unset;
  cursor: pointer;
  color: #599aff;
  font-size: 12px;
}
