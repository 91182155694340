.container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.200691);
  background-color: white;
  /* gap: 15px; */
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 220px;
  max-height: 300px;

  padding: 12px;
  gap: 8px;

  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
}

.status {
  padding: 6px;
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  min-height: 40px;
}

.status:hover {
  cursor: pointer;
  opacity: 0.8;
}
