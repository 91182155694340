.container button:not(.menuButton):not(.tag) {
  border: 0;
  padding: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
  background: none;
}

.container button img {
  filter: invert(95%) sepia(61%) saturate(6313%) hue-rotate(201deg) brightness(94%) contrast(91%);
}

.header {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #9391a6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header button {
  outline: none;
  background-color: transparent;
  border: none;
}

.hr {
  margin-bottom: 20px;
  margin-top: 10px;
  border: 0.5px solid #dadce0;
}

.calendar {
  display: grid;
  grid: auto / repeat(7, auto);
  row-gap: 2px;
  box-sizing: border-box;
}

.dayName {
  font-family: "Poppins";
  display: inline;
  font-weight: 400;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #919191;
}

.day {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.4px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 31px;
  height: 31px;
}

.inRange {
  background-color: #eff3f8 !important;
}

.selected {
  background-color: var(--blue) !important;
  color: white !important;
}

.rangeStart {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rangeEnd {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.day:not(.selected):hover {
  background-color: #eff3f8 !important;
}
