.wrapper {
  background: var(--dark);
  border-radius: 4px;
  padding: 8px;
  z-index: 100;
}

.toolbarActions {
  width: fit-content;
  display: flex;
  gap: 10px;
  max-height: 42px;
  align-items: center;
}

.remove {
  all: unset;
  cursor: pointer;
  display: flex;
  padding: 0px 4px;
}

.remove:hover {
  background-color: #354660;
}

.action {
  box-sizing: border-box;
  padding: 6px;
  border-radius: 2px;
  width: 32px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seperator {
  height: 26px;
  width: 2px;
  background-color: #657e9a;
}

.tooltip > div {
  background-color: var(--dark);
}
.tooltip {
  height: 30px;
  top: -45px !important;
  font-size: 12px;
  border-radius: 2px;
}
