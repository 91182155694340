.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0px 15px 50px 0px rgb(0 0 0 / 30%);

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;

  padding: 24px;
  width: 500px;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: 13px;
  width: 16px;
  height: 16px;
}

.title {
  color: #113357;
  font-family: Sora;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.098px;
}

.buttons {
  display: flex;
  gap: 12px;
}
