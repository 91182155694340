.wrapper {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 10px;
}

.button:hover {
  background: #d1e3ff;
}

.button {
  all: unset;
  padding: 5px 10px;
  background: #f0f1f5;

  color: #0072ff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.12px;

  display: flex;
  align-items: center;
  gap: 10px;
}
