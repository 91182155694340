.responsiveInputContainer {
  position: relative;
  min-width: 13em;
  width: min-content;

  cursor: text;
  background: white;
  border-radius: 2px;
  border: 2px solid #a1ccff;
  font: 12px/12px Poppins;
  padding: 2px;
}

.responsiveInputContainer > input {
  position: absolute;
  inset: 0;

  font: inherit;
  margin: 0;
  resize: none;
  outline: none;
  appearance: none;
  border: none;
}
.responsiveInputContainer::after {
  content: attr(data-value) " ";
  min-width: 13em;
  visibility: hidden;
  white-space: pre;
}
