.container {
  position: relative;
  display: flex;
  background: white;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  min-width: 700px;
}

.content {
  display: flex;
  align-self: stretch;
  width: 100%;
  place-content: center;
}

.logo {
  position: absolute;
  left: 33px;
  top: 30px;
}
