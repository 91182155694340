.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-top: -1em;
  height: 100vh;
  justify-content: center;
}

.title {
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.195px;
}

.subtitle {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
}

.bold {
  font-weight: 600;
}

.container a:hover {
  opacity: 0.5;
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.213px;
  display: inline;
}

.footer a {
  text-decoration: underline;
  color: #1973ff;
}
