.textarea {
  padding: 5px;
  display: inline-block;
  outline: 2px solid var(--blue);

  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  /* transform: translate(-50%, -50%); */
  /* width: fit-content; */
  cursor: text;
  min-width: 1px;
  height: auto;
}

.textarea[contenteditable]:empty::before {
  content: attr(placeholder);
  color: #848199;
  font: inherit;
  font-weight: 300;
}

.textarea[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
