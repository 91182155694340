.customDate {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 13px;
  padding: 5px;
}

.customDateOption {
  display: flex;
  align-items: center;
  gap: 17px;
}

.customDateWrapper {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.customDateRow {
  display: grid;
  align-items: center;
  gap: 6px;
  grid-template-columns: 40px auto;
  font-weight: 300;
  font-size: 14px;
}

.customDateRow[data-theme="dark"] {
  color: #657e9a;
}

.customDateValue {
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  background: #f5f7fa;
  width: 214px;
  height: 35px;
  color: #848199;
  display: flex;
  align-items: center;
  padding: 13px;
  font-weight: 300;
  font-size: 14px;
}

.customDateRow[data-theme="dark"] .customDateValue {
  background: #284666;
  color: white;
}

.customDateValueSelected {
  background: #ebedf3;
}

.customDateRow[data-theme="dark"] .customDateValueSelected {
  background: #426181;
}

.clearIcon {
  all: unset;
  position: absolute;
  right: 0px;
  margin-right: 12px;
}

.clearIcon:hover svg > path {
  stroke: #c2c3c9;
}

.tooltip > div {
  background-color: var(--dark);
  top: -2px !important;
}
.tooltip {
  height: 30px;
  top: 30px !important;
  font-size: 12px;
  border-radius: 2px;
}
