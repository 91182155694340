.checkboxContainer {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  height: 22px;
  width: 22px;
}

.checkboxContainer:focus {
  outline: 1px solid var(--blue);
}

.checkboxContainer:hover {
  border-color: var(--blue);
}

.checkboxContainer.checked {
  border-color: var(--blue);
  background-color: var(--blue);
}

.checkboxContainer.error {
  border-color: #bc3232;
  background-color: #fff;
}

.checkboxContainer.checked:after {
  content: "";
  width: 4px;
  height: 10px;
  border: solid;
  border-color: #ffffff;
  border-width: 0 2px 2px 0;
  transform-origin: left;
  transform: translate(8px, -4px) rotate(45deg);
}
