.layout {
  display: flex;
  flex-direction: column;
  place-items: flex-start;
  gap: 13px;
}

.hr {
  margin: 0;
  height: 1px;
  width: 100%;
  border: none;
  background-color: #657e9a;
}

.colorIconsGrid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 30px);
  grid-auto-rows: 30px;
  place-items: stretch;
}

.splitCellGrid {
  grid-template-columns: repeat(6, 30px);
}

.OpacityContainer {
  padding: 4px 4px;
  width: 120px;
  /* same as width of the color palette grid: 4 x 30x */
  font-size: 13px;
  display: flex;
  gap: 5px;
  width: 100%;
}

.Slider {
  background-image: url(/images/grey-checkerboard.png);
  background-repeat: repeat;
  background-size: 80px;
  background-position: cover;
  border-radius: 200px;
  height: 11px;
  place-self: center;
  flex-grow: 1;
}

.Label {
  color: #7891ae;
  font-size: 10px;
  width: 70px;
}

.opacitySlider {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.opacitySlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid #fff;
  cursor: ew-resize;
  /* border: 2px solid #FFF; */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
}

.colorButton {
  display: grid;
  place-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.colorButton:hover {
  background-color: transparent;
}

.ring {
  display: grid;
  place-items: center;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  background: none;
  place-self: stretch;
}

.minimalRing {
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  background: none;
}

.colorOptionContainer {
  display: grid;
  place-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.colorOptionContainer:hover {
  background-color: #d9d9d933;
}

.colorSolid {
  composes: colorButton;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid rgba(165, 170, 173, 0.5);
}

.colorTransparentSolid {
  composes: colorButton;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: url(/images/grey-checkerboard.png) repeat center/80px;
}

.customColorButtonContainer {
  composes: colorOptionContainer;
}

.customColorButton {
  composes: colorSolid;
  background: url(/images/custom-color-button.png) no-repeat center/10px;
}

.customColorPickerContainer {
  cursor: auto;
  position: fixed;
  z-index: 100;
}

.colorTransparentRing {
  composes: colorTransparentSolid;
  mask-image: radial-gradient(transparent 50%, white 50%);
}
