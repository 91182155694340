.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 33px;
  gap: 26px;
}

.footerButtonGroup {
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);*/
  display: flex;
  flex-direction: row;
  /*border-radius: 4px;*/
  border: 1px solid #ebedf3;
  background: white;
}

.footerButton {
  display: flex;
  font-family: inherit;
  border: none;
  background: white;
  margin: 0;
  padding: 0 5px;
  gap: 5px;
  height: 100%;
  width: auto;
  overflow: visible;
  outline: none;
  min-width: 45px;
  text-transform: none;
  pointer-events: visible;
  cursor: pointer;
}

.footerButton > * {
  margin: auto;
}

.footerButtonGroup > * + * {
  border-left: 1px solid #ebedf3;
}

/*
  border-radius property order:
       top-left, top-right, bottom-right, bottom-left
*/
.footerButtonGroup > *:first-child {
  border-radius: 4px 0 0 4px;
}

.footerButtonGroup > *:last-child {
  border-radius: 0 4px 4px 0;
}

.footerButtonGroup > *:only-child {
  border-radius: 4px;
}

.footerButton:hover {
  background: rgb(240, 240, 240);
}

.tooltip {
  background: var(--dark);
  box-shadow: 0px 8.72213px 6.97771px rgba(111, 114, 132, 0.0894161), 0px 4.88957px 3.91165px rgba(111, 114, 132, 0.075),
    0px 2.59681px 2.07745px rgba(111, 114, 132, 0.0605839), 0px 1.08059px 0.864475px rgba(111, 114, 132, 0.0421718);
  border-radius: 2px;
  padding: 5px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.142292px;
  color: #ffffff;

  display: grid;
  place-items: center;
}
