.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 100%;
}

.pickerContainer {
  background: #113357;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
}

.customRowTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;
  color: white;
}

.pickerContainer {
  background-color: #113357;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 4px;
  border-radius: 3px;
  width: 300px;
}

.pickerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 5px;
  border-radius: 3px;
}

.pickerRow:hover {
  background-color: #d9d9d933;
  cursor: pointer;
}

.pickerRow.selected {
  background-color: #d9d9d933;
}

.pickerRowTitle {
  flex: 1;
  color: white;
  font-size: 12px;
}

.pickerCheckmark {
  padding: 0 4px;
  opacity: 0;
}

.pickerRow.selected .pickerCheckmark {
  opacity: 100%;
}
